import CustomIcon from "../CustomIcon";

const OurChecksItem = ({ title, content, children, icon, color }) => {
  return (
    <div className="flex py-3 px-0">
      <CustomIcon icon={icon} color={color} />
      <div>
        <h3 className="text-[1rem] xs:text-[1.2rem] text-primary-blue font-semibold leading-[1.43] m-0">
          {title}
        </h3>
        <p className="text-[1rem] xs:text-[1.2rem] text-primary-blue font-normal leading-[1.43] m-0">
          {content}
        </p>
        {children}
      </div>
    </div>
  );
};

export default OurChecksItem;
