const VfStarFullWithHalfStar = ({
  fillFull,
  fillEmpty
}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 656.8 100"
  >
    <g id="Layer_1">
      <g>
        <path
          fill={fillFull}
          d="M600.8,2.9l-10.6,27.2c-0.7,1.7-2.2,2.8-3.9,2.9l-28.9,1.8c-4.1,0.2-5.8,5.4-2.6,8.1l22.3,18.4
			c1.3,1.1,2,3,1.5,4.6l-7.3,28.3c-1,4,3.3,7.2,6.8,5l24.3-15.8c0.8-0.5,1.7-0.8,2.6-0.7V0C603.3,0,601.6,1,600.8,2.9z"
        />
        <path
          fill={fillEmpty}
          d="M631.6,99.2c3.5,2.2,7.7-1,6.8-5L631.1,66c-0.3-1.6,0.3-3.3,1.7-4.4L655.2,43c3.2-2.6,1.5-7.8-2.6-8.1
			l-28.8-1.8c-1.7-0.1-3.3-1.2-3.9-2.9L609.2,2.9C608.4,1,606.7,0,605,0v82.7c0.8,0,1.5,0.3,2.2,0.7L631.6,99.2z"
        />
      </g>
    </g>
    <g id="Artwork">
      <path
        fill={fillFull}
        d="M79.7,61.5L102,42.9c3.2-2.6,1.5-7.8-2.6-8l-28.8-1.8c-1.7-0.1-3.3-1.2-3.9-2.9L56.1,2.9
		c-1.5-3.9-6.9-3.9-8.4,0L37.1,30.1c-0.7,1.7-2.2,2.8-3.9,2.9L4.3,34.7c-4.1,0.2-5.8,5.4-2.6,8L24,61.3c1.3,1.1,2,3,1.5,4.6
		l-7.3,28.3c-1,4,3.3,7.2,6.8,5l24.3-15.8c1.5-1,3.4-1,4.8,0l24.4,15.8c3.5,2.2,7.7-1,6.8-5L78,66C77.7,64.4,78.2,62.6,79.7,61.5
		L79.7,61.5z"
      />
      <path
        fill={fillFull}
        d="M218,61.5l22.4-18.6c3.2-2.6,1.5-7.8-2.6-8l-28.8-1.8c-1.7-0.1-3.3-1.2-3.9-2.9L194.4,2.9
		c-1.5-3.9-6.9-3.9-8.4,0l-10.6,27.2c-0.7,1.7-2.2,2.8-3.9,2.9l-28.9,1.8c-4.1,0.2-5.8,5.4-2.6,8l22.4,18.5c1.3,1.1,2,3,1.5,4.6
		l-7.3,28.3c-1,4,3.3,7.2,6.8,5l24.3-15.8c1.5-1,3.4-1,4.8,0l24.4,15.8c3.5,2.2,7.7-1,6.8-5L216.3,66C216,64.4,216.5,62.6,218,61.5
		L218,61.5z"
      />
      <path
        fill={fillFull}
        d="M356.2,61.5l22.4-18.6c3.2-2.6,1.5-7.8-2.6-8l-28.8-1.8c-1.7-0.1-3.3-1.2-3.9-2.9L332.7,2.9
		c-1.5-3.9-6.9-3.9-8.4,0l-10.6,27.2c-0.7,1.7-2.2,2.8-3.9,2.9l-28.9,1.8c-4.1,0.2-5.8,5.4-2.6,8l22.4,18.5c1.3,1.1,2,3,1.5,4.6
		l-7.3,28.3c-1,4,3.3,7.2,6.8,5l24.3-15.8c1.5-1,3.4-1,4.8,0l24.4,15.8c3.5,2.2,7.7-1,6.8-5L354.6,66
		C354.3,64.4,354.8,62.6,356.2,61.5L356.2,61.5z"
      />
      <path
        fill={fillFull}
        d="M494.5,61.5l22.4-18.6c3.2-2.6,1.5-7.8-2.6-8l-28.8-1.8c-1.7-0.1-3.3-1.2-3.9-2.9L471,2.9
		c-1.5-3.9-6.9-3.9-8.4,0L452,30.1c-0.7,1.7-2.2,2.8-3.9,2.9l-28.9,1.8c-4.1,0.2-5.8,5.4-2.6,8l22.4,18.5c1.3,1.1,2,3,1.5,4.6
		l-7.3,28.3c-1,4,3.3,7.2,6.8,5l24.3-15.8c1.5-1,3.4-1,4.8,0l24.4,15.8c3.5,2.2,7.7-1,6.8-5L492.9,66
		C492.6,64.4,493.1,62.6,494.5,61.5L494.5,61.5z"
      />
    </g>
  </svg>
);
export default VfStarFullWithHalfStar;
