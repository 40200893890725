import RemoveRedEyeRoundedIcon from "@material-ui/icons/RemoveRedEyeRounded";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import { makeStyles } from "@material-ui/core";
import VfStar from "./vectors/VfStar";
import Call from "@material-ui/icons/Call";
import Vf2023Tick from "./vectors/Vf2023Tick";
import CustomQuestion from "./vectors/CustomQuestion";
import PlaceIcon from '@material-ui/icons/LocationOn';

const useStyles = makeStyles((theme) => ({
  red: {
    color: theme.palette.error.main
  },
  green: {
    color: theme.palette.secondary.dark
  },
  blue: {
    color: theme.palette.tertiary.main
  },
  mint: {
    color: theme.palette.secondary.main
  },
  navy: {
    color: theme.palette.primary.main
  },
  icon: {
    margin: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    borderRadius: '100%',
    border: '3px solid',
    padding: theme.spacing(1) /2,
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(2),
      width: theme.spacing(2),   
      margin: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(0)}px ${theme.spacing(0)}px`, 
      '& .MuiSvgIcon-root': {
        fontSize: "1rem",
        '&.vfStar': {
          fontSize: "0.8rem !important",
        }
      },
      '& .vfStar': {
        fontSize: "0.8rem !important",
      },
      '& .vf2023Tick, .vfCustomQuestion': {
        fontSize: "0.8rem !important",
      }
    },
  },
  iconDefault: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    '& .MuiSvgIcon-root': {
      fontSize: "2rem",
      color: 'inherit',
    },
    '& .vfStar': {
      fontSize: "1.5rem",
    },
    '& .vf2023Tick, .vfCustomQuestion': {
      fontSize: "1.5rem",
    },
  },
  iconSmall: {
    height: theme.spacing(2),
    width: theme.spacing(2),
    '& .MuiSvgIcon-root': {
      fontSize: "2rem",
      color: 'inherit',
    },
    '& .vfStar': {
      fontSize: "1.5rem",
    },
    '& .vf2023Tick, .vfCustomQuestion': {
      fontSize: "0.8rem",
    },
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  flexTop: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: "column"
  },
  iconxs: {
    height: theme.spacing(1.3),
    width: theme.spacing(1.3),
    '& .MuiSvgIcon-root': {
      fontSize: "1.5rem",
      color: 'inherit',
    },
    '& .vfStar': {
      fontSize: "1rem",
    },
    '& .vf2023Tick': {
      fontSize: "0.8rem",
    },
    border: '1.5px solid',
    margin: '0 0.3rem 0 0.1rem',
  },
  iconxsNoBorder: {
    height: theme.spacing(1.6),
    width: theme.spacing(1.6),
    '& .MuiSvgIcon-root': {
      fontSize: "1.5rem",
      color: 'inherit',
    },
    border: '1.5px transparent',
    margin: '0 0.3rem 0 0.1rem',
  },
}));

const icons = {
  star: <VfStar />,
  eye: <RemoveRedEyeRoundedIcon />,
  person: <PersonRoundedIcon />,
  phone: <Call />,
  check: <Vf2023Tick />,
  location: <PlaceIcon />,
  question: <CustomQuestion />
};

const CustomIcon = ({ icon, color, small, xsmall, xsmallNoBorder}) => {
  const classes = useStyles();

  return (
    <div className={classes.flexTop}>
    <div className={`${classes.icon} ${classes[color]} ${classes.flexCenter} ${ small ? classes.iconSmall : classes.iconDefault} ${ xsmall ? classes.iconxs : classes.iconDefault} ${ xsmallNoBorder ? classes.iconxsNoBorder : classes.iconDefault} vfIcon`}>
      {icons[icon]}
      </div>
      </div>
  );
};

export default CustomIcon;
