import Image from "next/image";
import {
  dmIcon,
  guardianIcon,
  independentIcon,
  itvIcon,
  sundaytimesIcon,
} from "../../assets/images";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export default function FeaturedInLogos() {
  const renderLogos = (item) => {
    return (
      <Image
        key={item.src}
        src={item.src}
        width={item.width}
        height={item.height}
        alt="Featured in logo"
      />
    );
  }

  const renderLogosMobile = (item) => {
    return (
      <div key={item.src} className="mb-6 mt-2 text-center">
        <Image
          src={item.src}
          width={item.width}
          height={item.height}
          className="m-auto"
          alt="Featured in logo"
        />
      </div>
    )
  }

  const featuredInLogos = [
    { src: sundaytimesIcon, width: 176.533, height: 32 },
    { src: itvIcon, width: 64.2, height: 32 },
    { src: guardianIcon, width: 97.2833, height: 32 },
    { src: independentIcon, width: 200, height: 18.4167 },
    { src: dmIcon, width: 196.317, height: 32 },
  ]

  return (
    <>
      <div className="sm:flex hidden py-8 px-[72px] bg-pale-mint-green items-center justify-between w-full flex-wrap box-border">
        {featuredInLogos.map(renderLogos)}
      </div>

      <div className="sm:hidden overflow-hidden bg-pale-mint-green min-h-[88px] pt-4">
        <Carousel
        ssr={true}
        containerClass=""
        arrows={false}
        showDots={true}
        dotListClass="[&_.react-multi-carousel-dot--active_button]:bg-mint-green [&_button]:!border-0 [&_button]:bg-white"
        autoPlay={true}
        autoPlaySpeed={2000}
        infinite={true}
        responsive={{
          mobile: {
            breakpoint: { max: 960, min: 0 },
            items: 1
          }
        }}>
          {featuredInLogos.map(renderLogosMobile)}
        </Carousel>
      </div>
    </>
  )
}
