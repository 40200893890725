import Image from "next/future/image";
import { logoWithVerticalsBlueOnWhite } from "../../assets/images";
import StarRatingDisplay from "../StarRatingDisplay";

export function CustomerExperience({ data }) {
  const { stats } = data;

  return (
    <div className="px-10 xs:py-6 xs:px-20 sm:p-0">
      <div className="w-full flex flex-wrap box-border">
        <div className="m-0 box-border grow-0 sm:max-w-[33.333333%] sm:basis-1/3 max-w-full basis-full">
          <div className="m-8 md:my-16 md:mx-0 flex py-1 px-0 items-center flex-col">
            <strong>
              Average rating from customers
            </strong>
            <div className="flex">
              <div className="flex flex-col justify-center">
                <div className="text-[48px] xs:text-[64px] font-bold">{stats.avgVal}</div>
              </div>
              <div className="flex p-2 text-[20px] font-bold flex-col justify-center">
                <div>out</div>
                <div>of 5</div>
              </div>
            </div>
            <div className="text-center [&_svg]:text-[23px] [&_svg]:mr-[7px]">
              <StarRatingDisplay rating={5} />
            </div>
          </div>
        </div>
        <div className="m-0 box-border grow-0 sm:max-w-[33.333333%] sm:basis-1/3 max-w-full basis-full">
          <div className="m-8 md:my-16 md:mx-0 flex py-1 px-0 items-center flex-col border-none sm:border sm:border-solid sm:border-l-contrast-grey sm:border-t-0 sm:border-b-0 sm:border-r-contrast-grey">
            <span className="text-[48px] xs:text-[64px] font-bold">{stats.prc_recommend}</span>
            <strong>
              of people recommend
            </strong>
            <Image
              src={logoWithVerticalsBlueOnWhite} 
              width={168}
              height={28.75}
              alt="VouchedFor logo"
              className="max-w-[80%] xs:max-w-[50%] w-[168px] mt-4"
            />
          </div>
        </div>
        <div className="m-0 box-border grow-0 sm:max-w-[33.333333%] sm:basis-1/3 max-w-full basis-full">
          <div className="m-8 md:my-16 md:mx-0 flex py-1 px-0 items-center flex-col">
            <strong>
              To date, more than
            </strong>

            <span className="text-[48px] xs:text-[64px] font-bold">{stats.reviews}</span>
            <strong>
              client reviews have been left
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerExperience;
