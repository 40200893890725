import { vfColours } from "../../Theme";
import VfStarFullWithHalfStar from "../vectors/VfStarFullWithHalfStar";
import { testimonialImage } from '../../assets/images';
import Image from "next/image";

const Testimonial = () => {
  return (
    <div className="md:py-16 md:px-36 xs:py-8 xs:px-16 p-8">
      <div className="w-full flex flex-wrap box-border">
        <div className="sm:flex hidden p-8 items-center justify-start grow-0 max-w-[50%] basis-1/2 m-0 box-border">
          <Image
            src={testimonialImage}
            alt='Testimonial image'
            width={440}
            height={473}
          />
        </div>
        <div className="flex p-8 items-center justify-start sm:grow-0 sm:max-w-[50%] sm:basis-1/2 m-0 box-border">
          <div>
            <h2 className="text-primary-blue xs:text-[2.2rem] text-[1.3rem] md:text-[2.7rem] leading-[1.25] mb-8 font-normal m-0">
              "VouchedFor made the process of finding a good adviser a lot easier"
            </h2>
            <div className="sm:w-[150px] sm:h-[30px] w-[100px] h-[20px]">
              <VfStarFullWithHalfStar fillEmpty={vfColours.paleMintGreen} fillFull={vfColours.mintGreen} />
            </div>
            
            <h3 className="text-mint-green text-[0.9rem] sm:text-[1.3rem] mt-8 font-bold tracking-[1px] uppercase leading-[1.5] m-0">
                David, London
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
