import React from 'react';
import PageWrapper from '../src/containers/PageWrapper';
import CustomerExperience from '../src/components/Home/CustomerExperience';
import OurChecks from '../src/components/Home/OurChecks';
import FeaturedInLogos from '../src/components/Home/FeaturedInLogos';
import PageHead from '../src/components/PageHead';
import content from '../src/data/homepage-data.json';
import Testimonial from '../src/components/Home/Testimonial';

const Homepage = () => {
    return (
        <PageWrapper noPadding pathname='/'>
            <PageHead desc="UK’s leading review site for Financial Advisers, Mortgage Advisers, Solicitors and Accountants. Search 1000s of vetted advisers and over 110,000 reviews from real clients. All advisers checked with the FCA, ACCA or SRA." />
            <FeaturedInLogos />
            <Testimonial />
            <OurChecks data={content.OurChecks} />
            <CustomerExperience data={content.CustomerExperience} />
        </PageWrapper>
    );
};

export default Homepage;
