import React, {useEffect, useState} from 'react';
import VfStar from './vectors/VfStar';
import {isIE11} from '../utils/utilities';

export default function StarRatingDisplay(
    {
        rating,
        centered,
        pixelSize = '25px',
    }
) {
    const [isBrowserIE11, setIsBrowserIE11] = useState(false);

    useEffect(() => {
        setIsBrowserIE11(isIE11())
    }, [])

    const renderStars = (value, index) => {
        return (
            <div className="leading-[0]" key={index}>
                {renderReadonlyStar(value)}
            </div>
        );
    };

    const renderReadonlyStar = (value) => {
        return (
            <VfStar
                key={value}
                className={`mr-2 max-sm:mr-1  
              ${rating < value ? 'text-pale-mint-green' : ''} 
              ${rating >= value ? 'text-mint-green' : ''} 
              `}
                pixelSize={pixelSize}
            />
        );
    };

    return (
        <div>
            <div
                className={`${
                    isBrowserIE11 ? 'block' : 'flex'
                } items-center w-full box-border flex-wrap `}
            >
                <div className="grow-0 basis-full m-0 box-border xs:max-w-full ">
          <span className={`flex ${centered ? 'justify-center' : ''}`}>
            {[1, 2, 3, 4, 5].map((v, index) =>
                renderStars(v.toString(), index)
            )}
          </span>
                </div>
            </div>
        </div>
    );
}
