import PropTypes from 'prop-types';
import Head from 'next/head';
import { isProductionEnv } from '../utils/utilities';
import { getAnalyticsSafeURL } from '../utils/analyticsUtilities';

const propTypes = {
    title: PropTypes.string,
    disableBots: PropTypes.bool,
    desc: PropTypes.string,
    keywords: PropTypes.string,
    canonical: PropTypes.string
};

const defaultProps = {
    disableBots: false,
    title: 'Find Top-Rated Financial Advisers, Mortgage Advisers, Solicitors and Accountants',
    desc: '',
    canonical: null,
    keywords: '',
    schema: '',
};

function PageHead({ canonical, disableBots, desc, keywords, title, children }) {
    const getCanonical = () => {
        if (canonical) {
            canonical = canonical.replace('http:', 'https:');
        }

        return getAnalyticsSafeURL(canonical)
    }

    const canonicalUrl = getCanonical();
    const preventCrawling = disableBots || !isProductionEnv();

    return (
        <Head>
            {preventCrawling && <meta name="googlebot" content="noindex, nofollow" />}
            {preventCrawling && <meta name="robots" content="noindex, nofollow" />}
            {!preventCrawling && <meta name="robots" content="index, follow" />}
            {desc && <meta name="description" content={desc} />}
            {keywords && <meta name="keywords" content={keywords} />}
            {children}
            {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
            <title>{title}</title>
        </Head>
    );
}

PageHead.defaultProps = defaultProps;
PageHead.propTypes = propTypes;

export default PageHead;
