import OurChecksItem from "./OurChecksItem";

const OurChecks = ({ data }) => {
  return (
    <div className="py-8 px-8 xs:px-16 sm:px-16 md:py-16 md:px-36 bg-pale-mint-green">
      <div className="w-full flex flex-wrap box-border">
        <div className="flex p-4 xs:p-8 items-start justify-center grow-0 sm:max-w-[50%] sm:basis-1/2 max-w-full basis-full m-0 box-border">
          <h2 className="text-primary-blue text-[1.6rem] xs:text-[2.4rem] sm:text-[3rem] md:text-[3.5rem] self-center font-bold leading-[1.25] m-0">
            VouchedFor advisers are checked more thoroughly than any other
          </h2>
        </div>
        <div className="flex p-4 xs:p-8 items-start justify-center grow-0 sm:max-w-[50%] sm:basis-1/2 max-w-full basis-full m-0 box-border">
          <div>
            {data.list.map((item) => (
              <OurChecksItem
                key={`our-checks:${item.title}`}
                title={item.title}
                content={item.text}
                icon={item.icon}
                color={item.color}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurChecks;
